<template lang="pug">
.default-head
  .wrapper
    router-link.logo(tag="h1", to="/")
      img(src="@/assets/images/logo.png")
    .nav-menu
      el-dropdown(
        trigger="click"
        placement="bottom"
        @visible-change="changeArrow")
        span.el-dropdown-link.c-pointer(:class="{'focus':arrowFocus}")
          | 全部分类
          i.icon-arrow.el-icon--right(:class="{'icon-arrow-up':arrowUp}")
        el-dropdown-menu.nav-category(
          slot="dropdown"
          :class="{'max-nav-category':allCategory.length > 10}")
          el-dropdown-item(v-for="item in allCategory" :key="item.id")
            router-link(tag="span", :to="{name:'categories',query:{id:item.id,title:item.name}}") {{item.name}}
      el-menu(
        mode="horizontal"
        background-color="transparent"
        :default-active="active"
        :text-color="menuOption.textColor"
        :active-text-color="menuOption.activeTextColor")
          
          el-menu-item(index="category")
            router-link(tag="a", to="/") 单品推广
          el-menu-item(index="product")
            router-link(tag="a", to="/product") 产品组合
          el-menu-item(index="guide")
            router-link(tag="a", to="/guide") 分销指南
    el-input.input-with-search(
      placeholder='搜索您心仪的课程'
      v-model='searchText'
      @keypress.enter.native="doSearch")
      el-button(slot='append' @click="doSearch") 搜索
</template>
// el-menu-item(index="index")
            router-link(tag="a", to="/index") 首页
<script>
import { getProductType } from "@/api/product.js";
export default {
    name: "defaultHead",
    data() {
        return {
            searchText: "",
            menuOption: {
                textColor: "#666",
                activeTextColor: "#333"
            },
            arrowUp: false,
            arrowFocus: false,
            allCategory: []
        };
    },
    mounted() {
        this.getCategory();
    },
    computed: {
        active() {
            return this.$route.meta.menu;
        }
    },
    methods: {
        getCategory() {
            getProductType().then(res => {
                if (res.code == 0) {
                    this.allCategory = res.data;
                }
            });
        },
        changeArrow(val) {
            if (val) {
                this.arrowUp = true;
                this.arrowFocus = true;
            } else {
                this.arrowUp = false;
                this.arrowFocus = false;
            }
        },
        doSearch() {
            if (this.searchText != "") {
                this.$router.push({
                    name: "searchResult",
                    query: { keywords: this.searchText }
                });
                this.searchText = "";
            }
        }
    }
};
</script>

<style lang="less">
@primaryColor: #2a67ff;
.default-head {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    font-size: 16px;
    color: #666;
    background: #fff;
    box-shadow: 0px 0px 2px 0px rgba(42, 103, 255, 0.3);
    .wrapper {
        display: flex;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        .logo {
            width: 180px;
            height: 38px;
            img {
                display: inline-block;
                width: 100%;
                height: 100%;
            }
        }
        .nav-menu {
            display: flex;
            width: 600px;
            align-items: center;
            .el-menu {
                border-bottom: none;
                .el-menu-item {
                    box-sizing: border-box;
                    width: 100px;
                    text-align: center;
                    transition: none;
                    padding: 0;
                    a {
                        display: inline-block;
                        width: 100%;
                        text-decoration: none;
                    }
                }
                .el-menu-item:hover,
                .el-menu-item.is-active {
                    background: transparent !important;
                    color: @primaryColor !important;
                    font-weight: 500;
                    border: none;
                }
                .el-menu-item.is-active::after {
                    content: "";
                    width: 48px;
                    height: 3px;
                    background: @primaryColor;
                    border-radius: 1.5px;
                    position: absolute;
                    bottom: 0;
                    left: 26px;
                }
            }
            .el-dropdown {
                height: 20px;
                .icon-arrow,
                .icon-arrow-up {
                    display: inline-block;
                    width: 0px;
                    height: 0px;
                    border-width: 3px;
                    border-style: solid;
                    border-color: #ccc transparent transparent transparent;
                    vertical-align: middle;
                }
                .icon-arrow-up {
                    border-color: transparent transparent #ccc transparent;
                    margin-top: -5px;
                }
                .el-dropdown-link:hover,
                .el-dropdown-link.focus {
                    color: @primaryColor;
                    .icon-arrow {
                        border-color: @primaryColor transparent transparent
                            transparent;
                    }
                    .icon-arrow-up {
                        border-color: transparent transparent @primaryColor
                            transparent;
                    }
                }
            }
        }
        .input-with-search {
            width: 280px;
            .el-input__inner {
                border-color: @primaryColor;
                color: #666;
            }
            .el-input-group__append {
                border-color: @primaryColor;
                background: @primaryColor;
                color: #fff;
                font-size: 14px;
                padding: 0 12px;
                .el-button {
                    font-weight: normal;
                }
            }
        }
    }
}
.el-dropdown-menu.nav-category {
    padding: 10px 12px;
    top: 84px !important;
    .el-dropdown-menu__item {
        width: 96px;
        text-align: center;
        padding: 0;
        line-height: 38px;
        color: #666;
        span {
            display: inline-block;
            width: 100%;
            height: 100%;
        }
    }
    .el-dropdown-menu__item:hover {
        color: #fff;
        background: linear-gradient(
            135deg,
            rgba(81, 123, 252, 1) 0%,
            rgba(81, 123, 252, 1) 100%
        );
        border-radius: 5px;
    }
}
.el-dropdown-menu.max-nav-category {
    width: 214px;
    padding: 10px 5px;
    .el-dropdown-menu__item {
        float: left;
        margin: 0 5px;
    }
}
</style>
