import { service } from "@/utils/request";
// import { api } from "@/api/baseurl";
// 获取广告信息
export const getPoster = spaceid => {
    return service.get("/poster/" + spaceid).then(result => {
        return result.data;
    });
};
// 获取推荐课程信息-----
export const getPosition = posid => {
    return service({
        method: "get",
        url: `/position/${posid}`,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
// 获取公告等信息
export const getInformation = (typeid, data) => {
    return service.get("/information/" + typeid, data).then(result => {
        return result.data;
    });
};
// 热词
export const getWordList = () => {
    return service.get("/words_list").then(result => {
        return result.data;
    });
};

// 升级弹窗信息
export const levelInfo = () => {
    return service({
        method: "get",
        url: "/prize/receive_info",
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};

//领取奖品
export const receiveGift = id => {
    return service({
        method: "post",
        url: "/prize/receive",
        params: id,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
