<template lang="pug">
.index-head
  .wrapper
    router-link.logo(tag="h1", to="/")
      img(src="@/assets/images/logo.png")
    .search-box
      el-input.input-with-search(
        placeholder='搜索您心仪的课程'
        v-model='searchText'
        @keypress.enter.native="doSearch")
        el-button(slot='append' @click="doSearch") 搜索
      .hot-list
        .words.c-pointer(
          :class="{'active':index==0}"
          v-for="(item,index) in hot_words"
          @click="changeSearchText(item.title)") {{item.title}}
</template>

<script>
import {getWordList} from '@/api/index'
export default {
  name: "indexHead",
  data() {
    return {
      searchText:'',
      hot_words:[]
    }
  },
  mounted(){
    getWordList().then(res => {
      if(res.code == 0 ){
        this.hot_words = res.data
      }
    })
  },
  methods:{
    changeSearchText(t){
      this.searchText = t
    },
    doSearch(){
      if(this.searchText != '') {
        this.$router.push({name:'searchResult',query:{keywords:this.searchText}})
        this.searchText = ''
      }
    }
  }
}
</script>

<style lang="less">
@primaryColor:#2A67FF;
.index-head{
  box-sizing: border-box;
  width:100%;
  height: 100px;
  font-size: 16px;
  color: #666;
  background:#fff;
  // box-shadow:0px 0px 2px 0px rgba(42,103,255,0.3);
  .wrapper{
    display: flex;
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    align-items: center; 
    .logo{
      width: 180px;
      height: 38px;
      img{
        display: inline-block;
        width: 100%;
        height: 100%;
      }
    }
    .search-box{
      width: 623px;
      margin-left: 50px;
      .input-with-search{
        width: 623px;
        .el-input__inner{
          border-color: @primaryColor;
          color: #666;
        }
        .el-input-group__append{
          border-color: @primaryColor;
          background: @primaryColor;
          color: #fff;
          font-size: 14px;
          padding: 0 12px;
          .el-button{
            width: 90px;
            font-weight: normal;
          }
        }
      }
      .hot-list{
        display: flex;
        flex-wrap: wrap;
        width: 623px;
        height: 18px;
        overflow: hidden;
        margin-top: 10px;
        .words{
          font-size: 13px;
          line-height: 18px;
          margin-right: 15px;
          color: #999;
        }
        .words:hover{
          color: @primaryColor;
        }
      }
    }
  }
}
</style>
