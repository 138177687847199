import axios from "axios";
import { Loading, Message } from "element-ui";
import router from "@/router";
import store from "@/store";

// api service
const API = process.env.VUE_APP_BASE_API;

const service = axios.create({
    // 设置超时时间
    timeout: 20000,
    baseURL: API,
    headers: {
        Accept: "application/json; charset=utf-8",
        "Content-Type": "application/json; charset=utf-8"
    }
});

let loading = null;
service.interceptors.request.use(
    config => {
        if (!config.noLoading) {
            loading = Loading.service({
                text: "请稍等..."
            });
        }
        if (config.isToken) {
            if (localStorage.userInfo) {
                config.headers.authorization = JSON.parse(
                    localStorage.userInfo
                ).authorization+'1';
            }
        }
        if (config.isCreateOrder) {
            // console.log(config);
            config.headers.platform = "web";
            config.headers.appversion = "distributor";
            config.headers.imei = "user-agent";
            config.headers.phonemodel = "iteration4";
            config.headers.systemversion = "web";
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

service.interceptors.response.use(
    response => {
        if (loading) {
            loading.close();
        }
        //token 过期退出并返回首页
        if (response.data.code == 100401 || response.data.code == 100410) {
            store.dispatch("UPDATA_USER_INFO", null);
            localStorage.clear();
            setTimeout(() => {
                router.replace({ path: "/" });
            }, 1000);
            response.data.msg = "登录状态已过期，请重新登录";
        }
        if (response.data.code != 0) {
            Message({
                message: response.data.msg,
                type: "error",
                duration: 1500
            });
        }
        return Promise.resolve(response);
    },
    error => {
        if (loading) {
            loading.close();
        }
        // 判断请求异常信息中是否含有超时timeout字符串
        if (error.message && error.message.includes("timeout")) {
            Message({
                message: "请求超时",
                type: "error",
                duration: 1500
            });
        } else {
            Message({
                message: "请求失败" + error.message,
                type: "error",
                duration: 1500
            });
        }
        return Promise.reject(error);
    }
);
export { axios, service };
