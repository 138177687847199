<template lang="pug">
.top-bar
  .wrapper
    router-link.home-bar.c-pointer(tag="div" to='/')
      img(src="@/assets/images/home-bar.png")
      span 网站首页
    .right-bar
      .user-bar
        template(v-if="userInfo")
          img.user-avatar(:src="userInfo.avatar ? userInfo.avatar : require('@/assets/images/default-avatar.jpg')")
          span.user-name {{userInfo.nickname}}
          span.logout.c-pointer(@click="toLogout") [退出]
        template(v-else)
          span.login.c-pointer(@click="toLogin") 登录
          span.mx-1 |
          router-link.register.c-pointer(tag="span" to='/register') 注册
      el-popover(
        placement='bottom'
        width='114'
        trigger='hover'
        popper-class="zhiya-app-popper")
        img.qr-code(src="@/assets/images/qr-code.jpg")
        .zhiya-app.c-pointer(slot='reference')
          img(src="@/assets/images/app-bar.png")
          span 知鸦APP
      .personal-bar.c-pointer(@click="toPersonal")
        img(src="@/assets/images/personal-bar.png")
        span 个人中心
</template>

<script>
export default {
    name: "topBar",

    data() {
        return {};
    },
    computed: {
        userInfo() {
            return this.$store.state.userInfo.userInfo;
        }
    },
    methods: {
        toLogin() {
            this.$store.commit("CONTROL_LOGIN_DIALOG", true);
        },
        toLogout() {
            this.$store.dispatch("UPDATA_USER_INFO", null);
            if (this.$route.name == "personal") {
                this.$router.push({ path: "/" });
            }
        },
        toPersonal() {
            if (this.userInfo) {
                this.$router.push({ name: "personal" });
            } else {
                this.toLogin();
            }
        }
    }
};
</script>

<style lang="less">
.top-bar {
    box-sizing: border-box;
    width: 100%;
    height: 36px;
    font-size: 13px;
    color: #999;
    background: #f2f3fa;
    .wrapper {
        display: flex;
        max-width: 1200px;
        height: 100%;
        margin: 0 auto;
        justify-content: space-between;
        align-items: center;
        .home-bar {
            img {
                width: 18px;
                height: 18px;
                vertical-align: -4px;
                margin-right: 4px;
            }
        }
        .right-bar {
            display: flex;
        }
        .user-bar {
            .user-avatar {
                width: 20px;
                height: 20px;
                border-radius: 50%;
            }
            .user-name {
                margin: 0 5px;
            }
            .login:hover,
            .register:hover {
                color: #2a67ff;
            }
            .logout {
                color: #fb3e31;
            }
        }
        .zhiya-app {
            margin: 0 40px 0 45px;
            img {
                width: 18px;
                height: 18px;
                margin-right: 4px;
                vertical-align: -4px;
            }
        }
        .personal-bar {
            img {
                width: 18px;
                height: 18px;
                margin-right: 4px;
                vertical-align: -4px;
            }
        }
    }
}
.el-popover.zhiya-app-popper {
    min-width: 114px !important;
    height: 114px;
    padding: 2px;
    border: 1px solid #ced1e9;
    .qr-code {
        width: 114px;
        height: 114px;
    }
    .popper__arrow {
        border-bottom-color: #ced1e9;
    }
}
</style>
