/**
 * 时间：2018-12-03 11：00：00
 * 把所有utils下文件用vue方式安装
 */
import topBar from '@/components/top-bar'
import firstHead from '@/components/first-head'
import indexHead from '@/components/index-head'
import bottomFoot from '@/components/bottomFoot'
// import cEmpty from '@/components/empty'
const install = Vue => {
  // 阿拉伯数字转中文
  Vue.prototype.NoToChinese = (num) => {
    if (!/^\d*(\.\d*)?$/.test(num)) {
        alert("Number is wrong!");
        return "Number is wrong!";
    }
    var AA = new Array("零", "一", "二", "三", "四", "五", "六", "七", "八", "九");
    var BB = new Array("", "十", "百", "千", "万", "亿", "点", "");
    var a = ("" + num).replace(/(^0*)/g, "").split("."),
        k = 0,
        re = "";
    for (var i = a[0].length - 1; i >= 0; i--) {
        switch (k) {
            case 0:
                re = BB[7] + re;
                break;
            case 4:
                if (!new RegExp("0{4}\\d{" + (a[0].length - i - 1) + "}$").test(a[0]))
                    re = BB[4] + re;
                break;
            case 8:
                re = BB[5] + re;
                BB[7] = BB[5];
                k = 0;
                break;
        }
        if (k % 4 == 2 && a[0].charAt(i + 2) != 0 && a[0].charAt(i + 1) == 0) re = AA[0] + re;
        if (a[0].charAt(i) != 0) re = AA[a[0].charAt(i)] + BB[k % 4] + re;
        k++;
    }
    if (a.length > 1) //加上小数部分(如果有小数部分) 
    {
        re += BB[6];
        for (var i = 0; i < a[1].length; i++) re += AA[a[1].charAt(i)];
    }
    return re;
  }
  // 校验手机号
  Vue.prototype.checkTel = function(data) {
    //验证手机号正则
    const reg = /^1[3456789]\d{9}$/
    return !reg.test(data) ? true : false;
  }
  // 校验密码
  Vue.prototype.checkPasswordLength = function(data) {
    //密码6位以上，数字字母
    const reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,}$/;
    return !reg.test(data) ? true : false;
  }
  Vue.prototype.checkNumber = function(data) {
    //数字
    const reg = /^\d{6}$/;
    return !reg.test(data) ? true : false;
  }
  Vue.prototype.checkMoney = function(data) {
    //数字
    const reg = /^(([1-9][0-9]*)|(([0]\.\d{1,2}|[1-9][0-9]*\.\d{1,2})))$/;
    return !reg.test(data) ? true : false;
  }
  // 成功提示
  Vue.prototype.successMes = function(text,time) {
    this.$message({
      message:text,
      type:'success',
      duration:time?time:2000
    })
  }
  // 警告提示
  Vue.prototype.warningMes = function(text,time) {
    this.$message({
      message:text,
      type:'warning',
      duration:time?time:2000
    })
  }
  // 错误提示
  Vue.prototype.errorMes = function(text) {
    this.$message.error(text)
  }
  // 信息提示
  Vue.prototype.tipMes = function(text,time) {
    this.$message({
      message:text,
      duration:time?time:2000
    })
  }
  // Vue.component('cEmpty', cEmpty)
  Vue.component('topBar',topBar)
  Vue.component('firstHead',firstHead)
  Vue.component('indexHead',indexHead)
  Vue.component('bottomFoot',bottomFoot)
}

export default {
  install
}
