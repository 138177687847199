import { service } from "@/utils/request";
// import { api } from "@/api/baseurl";
// 获取产品类型
export const getProductType = () => {
    return service.get("/product/type").then(result => {
        return result.data;
    });
};
// 搜索产品(单品) -----
export const getProduct = data => {
    return service({
        method: "get",
        url: "/product/search",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });

    // return service.get("/product/search", data).then(result => {
    //     return result.data;
    // });
};

// 产品详情(单品)
export const getProductDetail = (id, data) => {
    return service.get("/product/detail/" + id, data).then(result => {
        return result.data;
    });
};

// 搜索产品(组合)--------
export const getProducts = data => {
    return service({
        method: "get",
        url: "/product/combination/search",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
    // return service.get("/product/combination/search", data).then(result => {
    //     return result.data;
    // });
};
// 产品详情(组合)
export const getProductsDetail = (id, data) => {
    return service
        .get("/product/combination/detail/" + id, data)
        .then(result => {
            return result.data;
        });
};

// 获取分销链接二维码
export const getDistributionUrl = (id, data) => {
    return service.get("/product/distribution_url/" + id, data).then(result => {
        return result.data;
    });
};
// 获取产品状态
export const getProductStatus = () => {
    return service.get("/product/status_list").then(result => {
        return result.data;
    });
};
// 获取订单状态
export const getOrderStatus = () => {
    return service.get("/product/order_status_list").then(result => {
        return result.data;
    });
};

//获取分享海报
export const getDistributionPosters = id => {
    return service({
        method: "get",
        url: `/distributor/poster/${id}`,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};

//替换微信公众平台链接
export const expSrc = () => {
    return service({
        method: "get",
        url: "/wx_img"
    });
};
