<template lang="pug">
  .bottom-foot
    .wrapper
      p 网站备案/许可证号：
        a(href="https://beian.miit.gov.cn/" target="_blank") 京ICP备17065032号-24
      p 公安备案：11010802025038
</template>

<script>
export default {
  name: "bottomFoot",
  data() {
    return {
    }
  },
  computed:{
  }
}
</script>

<style lang="less">
.bottom-foot{
  box-sizing: border-box;
  position: relative;
  width:100%;
  min-width: 1200px;
  height: 90px;
  padding: 20px 0;
  background:linear-gradient(117deg,rgba(24,32,61,1) 0%,rgba(34,46,80,1) 100%);
  font-size: 14px;
  line-height: 26px;
  color: #fff;
  .wrapper{
    width: 1200px;
    height: 100%;
    margin: 0 auto;
    text-align: center;
    a{
      color: white;
      text-decoration: none;
    }
  }
}
</style>
