import Vue from "vue";
import store from "@/store";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
    // {
    //     path: "/",
    //     name: "index",
    //     alias: "/index",
    //     component: () => import("@/views/index.vue"),
    //     meta: {
    //         title: "分销平台",
    //         menu: "index"
    //     }
    // },
    {
        path: "/",
        // alias: "/index",
        name: "category",
        component: () => import("@/views/category-list.vue"),
        meta: {
            title: "单品推广",
            menu: "category"
        }
    },
    {
        path: "/product",
        name: "product",
        component: () => import("@/views/category-list.vue"),
        meta: {
            title: "产品组合",
            menu: "product"
        }
    },
    {
        path: "/categories",
        name: "categories",
        component: () => import("@/views/category-list.vue"),
        meta: {
            title: "全部分类"
        }
    },
    {
        path: "/searchResult",
        name: "searchResult",
        component: () => import("@/views/category-list.vue"),
        meta: {
            title: "搜索结果"
        }
    },
    {
        path: "/courseDetail",
        name: "courseDetail",
        component: () => import("@/views/course-detail.vue"),
        meta: {
            title: "课程详情"
        }
    },
    {
        path: "/cardDetail",
        name: "cardDetail",
        component: () => import("@/views/course-detail.vue"),
        meta: {
            title: "会员卡详情"
        }
    },
    {
        path: "/guide",
        name: "guide",
        component: () => import("@/views/guide.vue"),
        meta: {
            title: "分销指南",
            menu: "guide"
        }
    },
    {
        path: "/register",
        name: "register",
        component: () => import("@/views/login/register.vue"),
        meta: {
            title: "注册"
        }
    },
    {
        path: "/personal",
        name: "personal",
        component: () => import("@/views/personal.vue"),
        meta: {
            title: "个人中心",
            requireAuth: true
        }
    },
    {
        path: "/subject",
        name: "subject",
        component: () => import("@/views/subject.vue"),
        meta: {
            title: "学科入门",
            requireAuth: true
        }
    },
];

const router = new VueRouter({
    routes,
    mode: "history"
});
const def = {
    title: `分销平台`
};
const chgTitle = to => {
    if (document) {
        if (to.meta.title) {
            document.title =
                to.meta.title === def.title ? def.title : to.meta.title;
        } else {
            document.title = def.title;
        }
    }
};
router.beforeResolve((to, from, next) => {
    const resolver = promises => {
        chgTitle(to);
        next(promises[0]);
    };
    const awaits = [];
    awaits.push(
        new Promise(resolve => {
            if (to.matched.some(record => record.meta.requireAuth)) {
                if (!store.state.userInfo.userInfo) {
                    store.commit("CONTROL_LOGIN_DIALOG", true);
                } else {
                    resolve();
                }
            } else {
                resolve();
            }
        })
    );
    Promise.all(awaits).then(resolver);
});
export default router;
