import { service } from "@/utils/request";
// import { api } from "@/api/baseurl";

export const getCode = data => {
    return service.post("/send_code", data).then(result => {
        return result.data;
    });
};

export const getSubmitToken = () => {
    return service.get("/get_submit_token").then(result => {
        return result.data;
    });
};

export const login = data => {
    return service.post("/distributor/login", data).then(result => {
        return result.data;
    });
};

export const telLogin = data => {
    return service.post("/distributor/quick_login", data).then(result => {
        return result.data;
    });
};

export const register = data => {
    return service.post("/distributor/register", data).then(result => {
        return result.data;
    });
};

export const resetPasswd = data => {
    return service.post("/distributor/reset_passwd", data).then(result => {
        return result.data;
    });
};

export const updateNickname = data => {
    return service({
        method: "post",
        url: "/distributor/update_nickname",
        params: data,
        isToken: 1,
        isCreateOrder: 1
    }).then(result => {
        return result.data;
    });
};
