import 'swiper/dist/css/swiper.css'
import 'element-ui/lib/theme-chalk/index.css'
import '@/assets/styles/main.scss'

import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import install from "./utils/ins"
import Element from '@/plugins/element-ui'
import './icons'

Vue.use(Element)
Vue.use(install)
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
