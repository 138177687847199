<template lang="pug">
  el-dialog.login-container.reset-container(
    :visible='resetDialog'
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width='460px'
    append-to-body
    @close="resetDialogClose")
    .title 重置密码
    el-form.code-form.reset-form(
      ref='resetForm' 
      :model='resetForm' 
      :rules="rules" 
      :show-message="isSubmit"
      label-position="right")
      el-form-item(label='手机号:' prop="mobile")
        el-input(v-model='resetForm.mobile')
      el-form-item(label='验证码:' prop="code")
        el-input.code-input(v-model='resetForm.code')
        el-button.getcode-btn.primary-btn(:disabled="!canGetCode" @click='getCode') 获取验证码
      el-form-item(label='输入密码:' prop="password")
        el-input(v-model='resetForm.password' type="password")
      el-form-item(label='确认密码:' prop="password_confirmation")
        el-input(v-model='resetForm.password_confirmation' type="password")
      el-button.login-btn.primary-btn(:disabled="!canLogin" :loading="loginLoading" @click='resetSubmit') 立即重置
</template>

<script>
import { getCode, resetPasswd } from '@/api/login'
export default {
  name:'resetPwd',
  data() {
    const validateUsername = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入手机号"))
      } else if (this.checkTel(value)) {
        callback(new Error("手机号格式有误"))
      } else {
        callback()
      }
    }
    // 验证密码
    const validatePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"))
      } else if (this.checkPasswordLength(value)) {
        callback(new Error("密码长度不能小于6位,由数字和字母组成"))
      } else {
        callback()
      }
    }
    // 确认密码
    const validateSamePassword = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请确认密码"))
      } else if (this.checkPasswordLength(value)) {
        callback(new Error("密码长度不能小于6位,由数字和字母组成"))
      } else if (value != this.resetForm.password) {
        callback(new Error("新旧密码须完全一致"))
      } else {
        callback()
      }
    }
    const validateCode = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入验证码"))
      } else if (this.checkNumber(value)) {
        callback(new Error("验证码为6位数字"))
      } else {
        callback()
      }
    }
    return {
      resetForm:{
        mobile:'',
        code:'',
        password:'',
        password_confirmation:''
      },
      rules: {
        mobile: [{validator: validateUsername, trigger: "blur" }],
        code: [{ validator: validateCode, trigger: "blur"  }],
        password: [{ validator: validatePassword, trigger: "blur"  }],
        password_confirmation: [{ validator: validateSamePassword , trigger: "blur"  }]
      },
      canGetCode:true,
      canLogin:true,
      loginLoading:false,
      isSubmit:false,
      codeTimer:null
    }
  },
  computed:{
    resetDialog(){
      return this.$store.state.userInfo.resetDialog
    }
  },
  methods:{
    resetForm_(){
      if (this.codeTimer) {
        clearInterval(this.codeTimer)
        this.canGetCode = true
      }
      this.resetForm.password_confirmation = ''
      this.resetForm.password = ''
      this.resetForm.mobile = ''
      this.resetForm.code = ''
      this.canLogin = true
      this.loginLoading = false
      this.isSubmit = false
      this.$refs['resetForm'].resetFields()
    },
    resetSubmit(){
      this.$refs["resetForm"].validate(valid => {
        if (valid) {
          resetPasswd({
            ...this.resetForm
          }).then(res => {
            if (res.code == 0) {
              this.canLogin = false
              this.loginLoading = true
              this.successMes(res.msg)
              setTimeout(()=>{
                this.resetDialogClose()
              },500)
            }
          })
        } else {
          this.isSubmit = true
          this.errorMes('校验失败')
        }
      })
    },
    getCode(){
      if(this.resetForm.mobile != ''){
        if(!this.checkTel(this.resetForm.mobile)){
          getCode({
            mobile:this.resetForm.mobile,
            type:3
          }).then(res => {
            if (res.code == 0) {
              this.successMes(res.msg)
              this.startTimer()
            }
          })
        } else {
          this.errorMes('手机号格式有误')
        }
      } else {
        this.errorMes('请输入手机号')
      }
    },
    startTimer(){
      this.canGetCode = false
      // 定时器
      var s = 60
      if (this.codeTimer) {
        clearInterval(this.codeTimer)
      }
      this.codeTimer = setInterval(() => {
        s--
        if (s == 0) {
          clearInterval(this.codeTimer)
          this.canGetCode = true
        }
      }, 1000)
    },
    resetDialogClose() {
      this.resetForm_()
      this.$store.commit('CONTROL_RESETPWD_DIALOG',false)
    }
  }
}
</script>

<style lang="less">
.reset-container{
  .el-dialog__body{
    padding: 40px;
    .el-form-item__label{
      width: 100%;
      text-align: left;
      font-size: 14px;
      color: #666;
      line-height: 20px;
      margin-bottom: 10px;
    }
    .el-form-item{
      margin-bottom: 30px;
      .el-input__inner{
        border-color: #d7dae2;
      }
      .code-input{
        width:272px ;
      }
      .getcode-btn{
        display: inline-block;
        width: 98px;
        font-size: 14px;
        color: #fff;
        height: 40px;
        text-align: center;
        margin-left: 10px;
      }
    }
    .login-btn{
      display: block;
      width: 120px;
      height: 40px;
      color: #fff;
      margin: 0 auto 17px;
      border-radius: 5px;
      font-size: 14px;
      line-height: 40px;
      padding: 0;
    }
  }
  .title{
    text-align: center;
    font-size: 18px;
    color: #333;
    font-weight: 500;
  }
}
</style>
