<template lang="pug">
  .app-container
    router-view
    comLogin
    resetPwd
</template>
<script>
import comLogin from '@/views/login'
import resetPwd from '@/views/login/resetPwd'
export default {
  components:{comLogin,resetPwd},
  data() {
    return {}
  }
}
</script>
<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333;
}
</style>
