<template lang="pug">
  //- 账密登录
  mixin primaryLogin()
    el-form.login-form(
      ref='loginForm'
      :model='loginForm'
      :rules="rules" 
      :show-message="isLoginSubmit"
      label-position="left")
      el-form-item(prop="mobile")
        el-input(v-model='loginForm.mobile' placeholder="请输入手机号")
      el-form-item.last-item(prop="password")
        el-input(v-model='loginForm.password' type="password" placeholder="请输入密码")
      .forget-password
        span.c-pointer(@click="goResetPwd") 忘记密码？
      el-button.login-btn.primary-btn(:disabled="!canLogin" :loading="loginLoading" @click='loginSubmit') 登录
    .go-register 还没有账号？
      router-link.c-pointer(tag="span" to='/register') 去注册
        img.arrow-icon(src="@/assets/images/arrow.png")
  //- 快捷登录
  mixin codeLogin()
    el-form.code-form(
      ref='codeForm' 
      :model='codeForm' 
      :rules="rules"
      :show-message="isCodeSubmit"
      label-position="left")
      el-form-item( prop="mobile")
        el-input(v-model='codeForm.mobile' placeholder="请输入手机号")
      el-form-item.get-code-item(prop="code")
        el-input.code-input(v-model='codeForm.code' placeholder="请输入手机验证码")
        el-button.getcode-btn.primary-btn(:disabled="!canGetCode" @click='getCode') 获取验证码
      el-button.login-btn.primary-btn(:disabled="!canLogin" :loading="loginLoading" @click='codeSubmit') 登录
    .go-register 还没有账号？
      router-link.c-pointer(tag="span" to='/register') 去注册
        img.arrow-icon(src="@/assets/images/arrow.png")
  el-dialog.login-container(
    :visible='loginDialog'
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    width='570px'
    append-to-body
    @close="loginDialogClose")
    .login-container-flex
      img.login-img(src="@/assets/images/login/login-bg.jpg")
      .login-content
        .login-title 登录
        el-tabs.login-tab(v-model="loginWay")
          el-tab-pane(label="账号登录" name="账号登录")
            +primaryLogin()
          el-tab-pane(label="快捷登录" name="快捷登录")
            +codeLogin()
</template>

<script>
import { getCode, login, telLogin } from "@/api/login";
// import { levelInfo } from "@/api/index";
import { setTimeout } from "timers";
export default {
    data() {
        const validateUserMobile = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入手机号"));
            } else if (this.checkTel(value)) {
                callback(new Error("手机号格式有误"));
            } else {
                callback();
            }
        };
        // 验证密码
        const validatePassword = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入密码"));
            } else if (this.checkPasswordLength(value)) {
                callback(new Error("密码长度不能小于6位,由数字和字母组成"));
            } else {
                callback();
            }
        };
        const validateCode = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请输入验证码"));
            } else if (this.checkNumber(value)) {
                callback(new Error("验证码为6位数字"));
            } else {
                callback();
            }
        };

        return {
            loginWay: "账号登录",
            loginForm: {
                // nickname:'',
                mobile: "",
                password: ""
            },
            codeForm: {
                mobile: "",
                code: ""
            },
            rules: {
                // nickname: [
                //     { required: true, message: "请输入账号", trigger: "blur" },
                //     {
                //         min: 1,
                //         max: 11,
                //         message: "长度在 1 到 11 个字符之间",
                //         trigger: "blur"
                //     }
                // ],
                password: [{ validator: validatePassword, trigger: "blur" }],
                mobile: [{ validator: validateUserMobile, trigger: "blur" }],
                code: [{ validator: validateCode, trigger: "blur" }]
            },
            canGetCode: true,
            canLogin: true,
            loginLoading: false,
            isLoginSubmit: false,
            isCodeSubmit: false,
            codeTimer: null
        };
    },
    computed: {
        loginDialog() {
            return this.$store.state.userInfo.loginDialog;
        }
    },
    methods: {
        resetForm() {
            if (this.codeTimer) {
                clearInterval(this.codeTimer);
                this.canGetCode = true;
            }
            this.loginForm.mobile = "";
            this.loginForm.password = "";
            this.codeForm.mobile = "";
            this.codeForm.code = "";
            this.canLogin = true;
            this.loginLoading = false;
            this.isLoginSubmit = false;
            this.isCodeSubmit = false;
            // this.$refs['codeForm'].resetFields()
            // this.$refs['loginForm'].resetFields()
        },
        loginSubmit() {
            this.$refs["loginForm"].validate(valid => {
                if (valid) {
                    login({
                        ...this.loginForm
                    }).then(res => {
                        if (res.code == 0) {
                            this.loginSucess(res.data.info);
                        }
                    });
                } else {
                    this.isLoginSubmit = true;
                    this.errorMes("校验失败");
                }
            });
        },
        codeSubmit() {
            this.$refs["codeForm"].validate(valid => {
                if (valid) {
                    telLogin({
                        ...this.codeForm
                    }).then(res => {
                        if (res.code == 0) {
                            this.loginSucess(res.data.info);
                        }
                    });
                } else {
                    this.isCodeSubmit = true;
                    this.errorMes("校验失败");
                }
            });
        },
        loginSucess(res) {
            this.canLogin = false;
            this.loginLoading = true;
            this.$store.dispatch("UPDATA_USER_INFO", {
                ...res
            });
            setTimeout(() => {
                this.loginDialogClose();
            }, 500);
        },
        getCode() {
            if (this.codeForm.mobile != "") {
                if (!this.checkTel(this.codeForm.mobile)) {
                    getCode({
                        mobile: this.codeForm.mobile,
                        type: 2
                    }).then(res => {
                        if (res.code == 0) {
                            this.successMes(res.msg);
                            this.startTimer();
                        }
                    });
                } else {
                    this.errorMes("手机号格式有误");
                }
            } else {
                this.errorMes("请输入手机号");
            }
        },
        startTimer() {
            this.canGetCode = false;
            // 定时器
            var s = 60;
            if (this.codeTimer) {
                clearInterval(this.codeTimer);
            }
            this.codeTimer = setInterval(() => {
                s--;
                if (s == 0) {
                    clearInterval(this.codeTimer);
                    this.canGetCode = true;
                }
            }, 1000);
        },
        goResetPwd() {
            // 去重置密码
            this.resetForm();
            this.$store.commit("CONTROL_RESETPWD_DIALOG", true);
        },
        loginDialogClose() {
            this.resetForm();
            this.$store.commit("CONTROL_LOGIN_DIALOG", false);
            // window.location.reload()
        }
    }
};
</script>

<style lang="less">
.login-container {
    .el-dialog__body {
        padding: 15px;
        background: #fff;
    }
    .login-container-flex {
        display: flex;
        width: 100%;
        height: 360px;
        .login-img {
            box-sizing: border-box;
            width: 213px;
            height: 100%;
            border-radius: 10px;
            margin-right: 30px;
        }
        .login-content {
            width: 282px;
            .login-title {
                width: 100%;
                color: #000;
                font-size: 24px;
                font-weight: 600;
                line-height: 1;
                text-align: center;
                margin-bottom: 20px;
                margin-top: 5px;
            }
            .login-tab {
                width: 100%;
                & > .el-tabs__header {
                    width: 100%;
                    height: 40px;
                    margin: 0 auto 35px;
                    .el-tabs__nav {
                        display: flex;
                        width: 100%;
                        justify-content: space-between;
                        align-items: center;
                        margin-left: -1px;
                        .el-tabs__active-bar {
                            display: none;
                        }
                        .el-tabs__item {
                            position: relative;
                            width: 141px;
                            height: 40px;
                            line-height: 40px;
                            text-align: center;
                            font-size: 14px;
                            color: #000;
                            padding: 0;
                            font-weight: normal;
                            background: #f5f7fb;
                        }
                        .el-tabs__item.is-active {
                            background: #e4ecff;
                        }
                        .el-tabs__item {
                            border-radius: 5px 0 0 5px;
                        }
                        .el-tabs__item:last-child {
                            border-radius: 0 5px 5px 0;
                        }
                    }
                    .el-tabs__nav-wrap::after {
                        display: none;
                    }
                }
            }
            .el-form-item {
                display: flex;
                margin-bottom: 20px;
                .el-form-item__content {
                    line-height: 40px;
                    font-size: 14px;
                    color: #333;
                    .el-input {
                        width: 282px;
                        .el-input__inner {
                            height: 40px;
                            line-height: 40px;
                            border: none;
                            background-color: #f5f7fb;
                        }
                    }
                }
            }
            .get-code-item {
                margin-bottom: 50px;
                .el-form-item__content {
                    display: flex;
                    width: 282px;
                    justify-content: space-between;
                }
            }
            .el-form-item.last-item {
                margin-bottom: 0;
            }
            .is-required .el-form-item__label::before {
                display: none;
            }
            .forget-password {
                text-align: right;
                font-size: 14px;
                color: #2a67ff;
                line-height: 20px;
                margin: 10px 0 20px;
            }
            .login-btn {
                display: block;
                width: 98px;
                height: 40px;
                color: #fff;
                margin: 0 auto 17px;
                border-radius: 5px;
                font-size: 14px;
                line-height: 40px;
                padding: 0;
            }
            .el-button.is-disabled {
                background: #ccc !important;
            }
            .go-register {
                width: 100%;
                text-align: center;
                font-size: 14px;
                color: #2a67ff;
                .arrow-icon {
                    width: 18px;
                    height: 18px;
                    vertical-align: middle;
                }
            }
            .code-form {
                .el-form-item {
                    .el-form-item__content {
                        .el-input {
                            width: 282px;
                        }
                        .code-input {
                            width: 184px;
                        }
                    }
                    .getcode-btn {
                        display: inline-block;
                        width: 93px;
                        font-size: 14px;
                        color: #fff;
                        padding: 9px 0;
                        text-align: center;
                        margin-left: 5px;
                    }
                }
            }
        }
    }
}
</style>
