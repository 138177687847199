const userInfo = localStorage.getItem("userInfo")
const state = {
  userInfo: userInfo ? JSON.parse(userInfo) : null,
  accountInfo:{},
  loginDialog:false,
  resetDialog:false
}
export default {
  state,
  mutations: {
    SET_USER_INFO(state, val) {
      state.userInfo = val
      if(val){
        localStorage.userInfo = JSON.stringify(val)
      } else {
        localStorage.removeItem('userInfo')
      }
    },
    CONTROL_LOGIN_DIALOG(state,val){
      state.loginDialog = val
    },
    CONTROL_RESETPWD_DIALOG(state,val){
      state.resetDialog = val
    },
    SET_ACCOUNT_INFO(state,val){
      state.accountInfo = val
    }
  },
  actions: {
    UPDATA_USER_INFO({ commit }, val) {
      commit("SET_USER_INFO", val)
    },
    UPDATA_ACCOUNT_INFO({ commit }, val) {
      commit("SET_ACCOUNT_INFO", val)
    },
    UPDATA_LOGIN_DIALOG({ commit }, val) {
      commit("CONTROL_LOGIN_DIALOG", val)
    },
    UPDATA_RESETPWD_DIALOG({ commit }, val) {
      commit("CONTROL_RESETPWD_DIALOG", val)
    }
  }
}
